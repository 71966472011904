@import '@crystal-eyes/constants.scss';
$content-bg-color: #f5f5f5;
.assessmentModal {
  width: 100vw;
  max-width: 900px;
}

.mbtiOption {
  width: 20px;
  height: 20px;
  padding: 0 !important;
  color: transparent;
  background-color: $content-bg-color;
}

.mbtiOptionContainer {
  column-gap: 40px !important;
  margin: 20px !important;

  @media (max-width: $tablet) {
    margin: 0 !important;
  }
}