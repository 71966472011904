@import '@crystal-eyes/constants.scss';

.pages {
  text-align: center;
  padding: 12px 0 0;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.page {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 2px;
  padding: 4px 8px;
  border-radius: 3px;
  color: #1e749f;
  font-size: 1rem;
  line-height: 1rem;
  font-family: var(--font-lato);
  font-weight: 400;
  text-decoration: none;
  transition: all 0.2s;
  &.ellipsis {
    pointer-events: none;
  }
  &.prev {
    transform: rotate(180deg);
  }
  &.arrow {
    margin: 0 4px;
    border-radius: 4px;
    padding: 4px;
  }
  &.text {
    color: $blue;
    text-transform: uppercase;
  }
  &:hover {
    background-color: $material-12;
    color: $material-54;
  }
  &.current {
    background-color: $blue;
    color: #fff;
    &:hover {
      background-color: $blue;
      color: #fff;
    }
  }
  &.disabled {
    pointer-events: none;
    color: #bbb;
    filter: saturate(0);
  }
}
