.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  cursor: pointer;

  :global(.modal-content) {
    cursor: default;
  }

  @media print {
    visibility: hidden;
  }
}
