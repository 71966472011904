@import '@crystal-eyes/constants.scss';

.container {
  display: inline-block;
  max-width: 100%;

  &.flex {
    flex: 1;
  }

  &.fullWidth {
    width: 100%;
  }

  &.hover {
    &:hover .overlay {
      opacity: 1;
      z-index: 10000;
      pointer-events: unset;
      transform: scale(1);
    }
  }

  &.noHover {
    .overlay {
      &.noHover {
        opacity: 1;
        z-index: 10000;
        pointer-events: unset;
        transform: scale(1);
      }
    }
  }
}

.overlay {
  display: inline-block;
  transition:
    transform 0.3s,
    opacity 0.3s,
    z-index 0.3s;
  position: fixed;
  z-index: -1;
  pointer-events: none;
  opacity: 0;

  &.withTransform {
    transform: scale(0.8, 0.8);

    &.topRight {
      transform-origin: bottom left;
    }
    &.topLeft {
      transform-origin: bottom right;
    }
    &.bottomRight {
      transform-origin: top left;
    }
    &.bottomLeft {
      transform-origin: top right;
    }
    &.bottom {
      transform-origin: top;
    }
    &.top {
      transform-origin: bottom;
    }
  }
}
