@import '@crystal-eyes/constants.scss';
@import '@crystal-eyes/helpers.scss';

.personalIntegrations {
  color: $blue;

  h2 {
    font-size: 1.125rem;
    font-weight: bold;
  }

  :global(.section-card-heading) {
    background-color: rgba(44, 167, 228, 0.04);
  }

  .section {
    display: flex;
    align-items: center;
    padding: 24px 0;
    @media (max-width: 800px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .img {
    width: 120px;
    margin-left: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 800px) {
      margin: 6px 24px;
    }
    img {
      width: 120px;
    }
  }

  .text {
    margin: 0 24px;
    flex: 1;
    color: $material-54;
    @media (max-width: 800px) {
      margin: 6px 24px;
    }
  }

  .text-links {
    margin-top: 10px;
  }

  .button {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 24px;
    @media (max-width: 800px) {
      margin: 6px 24px;
    }
  }

  .link {
    margin-top: 2px;
  }
}
