@import '@crystal-eyes/constants.scss';

.disc-color {
  &.empty {
    color: $blue;
  }
  &.D {
    color: $disc-D;
  }
  &.Di {
    color: $disc-Di;
  }
  &.DI {
    color: $disc-DI;
  }
  &.Id {
    color: $disc-Id;
  }
  &.I {
    color: $disc-I;
  }
  &.Is {
    color: $disc-Is;
  }
  &.IS {
    color: $disc-IS;
  }
  &.Si {
    color: $disc-Si;
  }
  &.S {
    color: $disc-S;
  }
  &.Sc {
    color: $disc-Sc;
  }
  &.SC {
    color: $disc-SC;
  }
  &.Cs {
    color: $disc-Cs;
  }
  &.C {
    color: $disc-C;
  }
  &.Cd {
    color: $disc-Cd;
  }
  &.CD {
    color: $disc-CD;
  }
  &.Dc {
    color: $disc-Dc;
  }
  &.blue {
    color: $blue;
  }
  &.red {
    color: $red;
  }
  &.midnight {
    color: $crystal-midnight-blue;
  }
  &.action-blue {
    color: $action-blue;
  }
  &.grey {
    color: $crystal-highlight-gray;
  }
  &.light-blue {
    color: $mid-blue;
  }
  &.white {
    color: $white;
  }
  &.black {
    color: $black;
  }
}

.disc-border-color {
  @mixin color-mixin($color) {
    border-color: $color;
    border-left-color: $color;
    border-top-color: $color;
    border-bottom-color: $color;
    border-right-color: $color;
  }
  &.empty {
    @include color-mixin($blue);
  }
  &.D {
    @include color-mixin($disc-D);
  }
  &.Di {
    @include color-mixin($disc-Di);
  }
  &.DI {
    @include color-mixin($disc-DI);
  }
  &.Id {
    @include color-mixin($disc-Id);
  }
  &.I {
    @include color-mixin($disc-I);
  }
  &.Is {
    @include color-mixin($disc-Is);
  }
  &.IS {
    @include color-mixin($disc-IS);
  }
  &.Si {
    @include color-mixin($disc-Si);
  }
  &.S {
    @include color-mixin($disc-S);
  }
  &.Sc {
    @include color-mixin($disc-Sc);
  }
  &.SC {
    @include color-mixin($disc-SC);
  }
  &.Cs {
    @include color-mixin($disc-Cs);
  }
  &.C {
    @include color-mixin($disc-C);
  }
  &.Cd {
    @include color-mixin($disc-Cd);
  }
  &.CD {
    @include color-mixin($disc-CD);
  }
  &.Dc {
    @include color-mixin($disc-Dc);
  }
  &.blue {
    @include color-mixin($blue);
  }
  &.red {
    @include color-mixin($red);
  }
}

.background-disc-color {
  @mixin color-mixin($color) {
    background-color: $color;
  }
  &.empty {
    @include color-mixin($blue);
  }
  &.D {
    @include color-mixin($disc-D);
  }
  &.Di {
    @include color-mixin($disc-Di);
  }
  &.DI {
    @include color-mixin($disc-DI);
  }
  &.Id {
    @include color-mixin($disc-Id);
  }
  &.I {
    @include color-mixin($disc-I);
  }
  &.Is {
    @include color-mixin($disc-Is);
  }
  &.IS {
    @include color-mixin($disc-IS);
  }
  &.Si {
    @include color-mixin($disc-Si);
  }
  &.S {
    @include color-mixin($disc-S);
  }
  &.Sc {
    @include color-mixin($disc-Sc);
  }
  &.SC {
    @include color-mixin($disc-SC);
  }
  &.Cs {
    @include color-mixin($disc-Cs);
  }
  &.C {
    @include color-mixin($disc-C);
  }
  &.Cd {
    @include color-mixin($disc-Cd);
  }
  &.CD {
    @include color-mixin($disc-CD);
  }
  &.Dc {
    @include color-mixin($disc-Dc);
  }
  &.blue {
    @include color-mixin($blue);
  }
  &.red {
    @include color-mixin($red);
  }
}
.background-disc-color-medium {
  @mixin background-mixin($color) {
    background-color: rgba($color, 0.2);
  }
  &.empty {
    @include background-mixin($blue);
  }
  &.D {
    @include background-mixin($disc-D);
  }
  &.Di {
    @include background-mixin($disc-Di);
  }
  &.DI {
    @include background-mixin($disc-DI);
  }
  &.Id {
    @include background-mixin($disc-Id);
  }
  &.I {
    @include background-mixin($disc-I);
  }
  &.Is {
    @include background-mixin($disc-Is);
  }
  &.IS {
    @include background-mixin($disc-IS);
  }
  &.Si {
    @include background-mixin($disc-Si);
  }
  &.S {
    @include background-mixin($disc-S);
  }
  &.Sc {
    @include background-mixin($disc-Sc);
  }
  &.SC {
    @include background-mixin($disc-SC);
  }
  &.Cs {
    @include background-mixin($disc-Cs);
  }
  &.C {
    @include background-mixin($disc-C);
  }
  &.Cd {
    @include background-mixin($disc-Cd);
  }
  &.CD {
    @include background-mixin($disc-CD);
  }
  &.Dc {
    @include background-mixin($disc-Dc);
  }
}

.background-disc-color-light {
  @mixin background-mixin($color) {
    background-color: rgba($color, 0.04);
  }
  &.empty {
    @include background-mixin($blue);
  }
  &.D {
    @include background-mixin($disc-D);
  }
  &.Di {
    @include background-mixin($disc-Di);
  }
  &.DI {
    @include background-mixin($disc-DI);
  }
  &.Id {
    @include background-mixin($disc-Id);
  }
  &.I {
    @include background-mixin($disc-I);
  }
  &.Is {
    @include background-mixin($disc-Is);
  }
  &.IS {
    @include background-mixin($disc-IS);
  }
  &.Si {
    @include background-mixin($disc-Si);
  }
  &.S {
    @include background-mixin($disc-S);
  }
  &.Sc {
    @include background-mixin($disc-Sc);
  }
  &.SC {
    @include background-mixin($disc-SC);
  }
  &.Cs {
    @include background-mixin($disc-Cs);
  }
  &.C {
    @include background-mixin($disc-C);
  }
  &.Cd {
    @include background-mixin($disc-Cd);
  }
  &.CD {
    @include background-mixin($disc-CD);
  }
  &.Dc {
    @include background-mixin($disc-Dc);
  }
}

.svg-disc-color {
  @mixin svg-mixin($color) {
    svg {
      path {
        stroke: $color;
      }
    }
  }
  &.empty {
    @include svg-mixin($blue);
  }
  &.D {
    @include svg-mixin($disc-D);
  }
  &.Di {
    @include svg-mixin($disc-Di);
  }
  &.DI {
    @include svg-mixin($disc-DI);
  }
  &.Id {
    @include svg-mixin($disc-Id);
  }
  &.I {
    @include svg-mixin($disc-I);
  }
  &.Is {
    @include svg-mixin($disc-Is);
  }
  &.IS {
    @include svg-mixin($disc-IS);
  }
  &.Si {
    @include svg-mixin($disc-Si);
  }
  &.S {
    @include svg-mixin($disc-S);
  }
  &.Sc {
    @include svg-mixin($disc-Sc);
  }
  &.SC {
    @include svg-mixin($disc-SC);
  }
  &.Cs {
    @include svg-mixin($disc-Cs);
  }
  &.C {
    @include svg-mixin($disc-C);
  }
  &.Cd {
    @include svg-mixin($disc-Cd);
  }
  &.CD {
    @include svg-mixin($disc-CD);
  }
  &.Dc {
    @include svg-mixin($disc-Dc);
  }
}

.enneagram-color {
  &.enneagram- {
    color: $material-54;
  }
  &.enneagram-1 {
    color: $enneagram-1;
  }
  &.enneagram-2 {
    color: $enneagram-2;
  }
  &.enneagram-3 {
    color: $enneagram-2;
  }
  &.enneagram-4 {
    color: $enneagram-4;
  }
  &.enneagram-5 {
    color: $enneagram-5;
  }
  &.enneagram-6 {
    color: $enneagram-6;
  }
  &.enneagram-7 {
    color: $enneagram-7;
  }
  &.enneagram-8 {
    color: $enneagram-8;
  }
  &.enneagram-9 {
    color: $enneagram-9;
  }
}

.enneagram-border-color {
  @mixin color-mixin($color) {
    border-color: $color;
  }
  &.enneagram- {
    @include color-mixin($material-54);
  }
  &.enneagram-1 {
    @include color-mixin($enneagram-1);
  }
  &.enneagram-2 {
    @include color-mixin($enneagram-2);
  }
  &.enneagram-3 {
    @include color-mixin($enneagram-3);
  }
  &.enneagram-4 {
    @include color-mixin($enneagram-4);
  }
  &.enneagram-5 {
    @include color-mixin($enneagram-5);
  }
  &.enneagram-6 {
    @include color-mixin($enneagram-6);
  }
  &.enneagram-7 {
    @include color-mixin($enneagram-7);
  }
  &.enneagram-8 {
    @include color-mixin($enneagram-8);
  }
  &.enneagram-9 {
    @include color-mixin($enneagram-9);
  }
}

.myers-briggs-color {
  &.type-ENFP {
    color: $ENFP;
  }
  &.type-ESFJ {
    color: $ESFJ;
  }
  &.type-ESFP {
    color: $ESFP;
  }
  &.type-INFP {
    color: $INFP;
  }
  &.type-INFJ {
    color: $INFJ;
  }
  &.type-ISFP {
    color: $ISFP;
  }
  &.type-ISFJ {
    color: $ISFJ;
  }
  &.type-INTP {
    color: $INTP;
  }
  &.type-INTJ {
    color: $INTJ;
  }
  &.type-ISTP {
    color: $ISTP;
  }
  &.type-ISTJ {
    color: $ISTJ;
  }
  &.type-ENTP {
    color: $ENTP;
  }
  &.type-ENTJ {
    color: $ENTJ;
  }
  &.type-ESTP {
    color: $ESTP;
  }
  &.type-ESTJ {
    color: $ESTJ;
  }
  &.type-ENFJ {
    color: $ENFJ;
  }

  &.type-empty {
    background-color: rgba($blue, 0.1);
  }
}

// Warning: Using rgba transparency to make background lighter can cause unintended layering issues
// Consider deprecating in favor of .disc-background-light-nt
.disc-background-light {
  @mixin color-mixin($color) {
    background-color: rgba($color, 0.07);
  }
  &.D {
    @include color-mixin($disc-D);
  }
  &.Di {
    @include color-mixin($disc-Di);
  }
  &.DI {
    @include color-mixin($disc-DI);
  }
  &.Id {
    @include color-mixin($disc-Id);
  }
  &.I {
    @include color-mixin($disc-I);
  }
  &.Is {
    @include color-mixin($disc-Is);
  }
  &.IS {
    @include color-mixin($disc-IS);
  }
  &.Si {
    @include color-mixin($disc-Si);
  }
  &.S {
    @include color-mixin($disc-S);
  }
  &.Sc {
    @include color-mixin($disc-Sc);
  }
  &.SC {
    @include color-mixin($disc-SC);
  }
  &.Cs {
    @include color-mixin($disc-Cs);
  }
  &.C {
    @include color-mixin($disc-C);
  }
  &.Cd {
    @include color-mixin($disc-Cd);
  }
  &.CD {
    @include color-mixin($disc-CD);
  }
  &.Dc {
    @include color-mixin($disc-Dc);
  }
}

.disc-background-light-nt {
  @mixin color-mixin($color) {
    background-color: $color;
  }
  &.D {
    @include color-mixin($disc-D-very-light);
  }
  &.Di {
    @include color-mixin($disc-Di-very-light);
  }
  &.DI {
    @include color-mixin($disc-DI-very-light);
  }
  &.Id {
    @include color-mixin($disc-Id-very-light);
  }
  &.I {
    @include color-mixin($disc-I-very-light);
  }
  &.Is {
    @include color-mixin($disc-Is-very-light);
  }
  &.IS {
    @include color-mixin($disc-IS-very-light);
  }
  &.Si {
    @include color-mixin($disc-Si-very-light);
  }
  &.S {
    @include color-mixin($disc-S-very-light);
  }
  &.Sc {
    @include color-mixin($disc-Sc-very-light);
  }
  &.SC {
    @include color-mixin($disc-SC-very-light);
  }
  &.Cs {
    @include color-mixin($disc-Cs-very-light);
  }
  &.C {
    @include color-mixin($disc-C-very-light);
  }
  &.Cd {
    @include color-mixin($disc-Cd-very-light);
  }
  &.CD {
    @include color-mixin($disc-CD-very-light);
  }
  &.Dc {
    @include color-mixin($disc-Dc-very-light);
  }
}

.disc-border-left-color {
  @mixin color-mixin($color) {
    border-left-color: $color;
  }
  &.empty {
    @include color-mixin($blue);
  }
  &.D {
    @include color-mixin($disc-D);
  }
  &.Di {
    @include color-mixin($disc-Di);
  }
  &.DI {
    @include color-mixin($disc-DI);
  }
  &.Id {
    @include color-mixin($disc-Id);
  }
  &.I {
    @include color-mixin($disc-I);
  }
  &.Is {
    @include color-mixin($disc-Is);
  }
  &.IS {
    @include color-mixin($disc-IS);
  }
  &.Si {
    @include color-mixin($disc-Si);
  }
  &.S {
    @include color-mixin($disc-S);
  }
  &.Sc {
    @include color-mixin($disc-Sc);
  }
  &.SC {
    @include color-mixin($disc-SC);
  }
  &.Cs {
    @include color-mixin($disc-Cs);
  }
  &.C {
    @include color-mixin($disc-C);
  }
  &.Cd {
    @include color-mixin($disc-Cd);
  }
  &.CD {
    @include color-mixin($disc-CD);
  }
  &.Dc {
    @include color-mixin($disc-Dc);
  }
  &.blue {
    @include color-mixin($blue);
  }
  &.red {
    @include color-mixin($red);
  }
}

.disc-border-bottom-color {
  @mixin color-mixin($color) {
    border-bottom-color: $color;
  }
  &.empty {
    @include color-mixin($blue);
  }
  &.D {
    @include color-mixin($disc-D);
  }
  &.Di {
    @include color-mixin($disc-Di);
  }
  &.DI {
    @include color-mixin($disc-DI);
  }
  &.Id {
    @include color-mixin($disc-Id);
  }
  &.I {
    @include color-mixin($disc-I);
  }
  &.Is {
    @include color-mixin($disc-Is);
  }
  &.IS {
    @include color-mixin($disc-IS);
  }
  &.Si {
    @include color-mixin($disc-Si);
  }
  &.S {
    @include color-mixin($disc-S);
  }
  &.Sc {
    @include color-mixin($disc-Sc);
  }
  &.SC {
    @include color-mixin($disc-SC);
  }
  &.Cs {
    @include color-mixin($disc-Cs);
  }
  &.C {
    @include color-mixin($disc-C);
  }
  &.Cd {
    @include color-mixin($disc-Cd);
  }
  &.CD {
    @include color-mixin($disc-CD);
  }
  &.Dc {
    @include color-mixin($disc-Dc);
  }
  &.blue {
    @include color-mixin($blue);
  }
  &.red {
    @include color-mixin($red);
  }
}

.noPageBreak {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.minWidth781 {
  display: none;

  @media (min-width: 781px) {
    display: block;
  }
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@mixin svg-bold() {
  width: 1.5em;
  height: 1.5em;
  stroke-width: 2;
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}
