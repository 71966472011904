@import '@crystal-eyes/constants.scss';

.creditUsageModal {
  background: $white;
  padding: 24px;
  min-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: 500px;
  overflow: auto;

  .closeBtn {
    padding: 8px !important;
  }
}
