@import '@crystal-eyes/constants.scss';

.container {
  display: block;
  margin: 8px 0 8px;
  color: $gray-text;
  font-family: var(--font-lato);
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  &.light {
    color: $material-54;
  }
}

.error {
  color: $red;
  font-size: 14px;
}

.title-container {
  display: flex;
  align-items: center;

  &.subtitleUnder {
    align-items: unset;
    flex-direction: column;
  }
}

.title {
  display: flex;
  align-items: center;

  &.titleError {
    color: $disc-D;
  }
}

.subtitle {
  margin-left: 4px;
  color: $material-54;
  font-size: 14px;

  &.subtitleUnder {
    margin: 8px 0;
  }
}

.optionalTag {
  border-radius: 3px;
  font-size: 14px;
  color: $gray-text;
  font-family: var(--font-lato);
  font-weight: 400;
  background: $gray-background;
  margin-left: 5px;
  padding: 0 2px;
  text-transform: lowercase;
}
