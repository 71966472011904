.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 32px;

  margin-top: 3rem;
  justify-content: center;
  align-self: center;
}
