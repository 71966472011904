@import '@crystal-eyes/constants.scss';

.table {
  border-spacing: 12px;
  color: $crystal-midnight-blue;
  font-size: 1rem;
  .tableHeader,
  .tableCell {
    text-align: left;
    padding: 8px 0;
    max-width: 200px;
  }
}
