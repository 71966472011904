@import '@crystal-eyes/helpers.scss';

.discSignalsTooltip {
  font-size: 1rem;

  & > :global(.icon) {
    color: #aeaeae;
  }

  :global(.signal-data) {
    justify-content: flex-start;

    ul {
      margin: 5px 0;
    }

    li {
      text-align: left;
    }

    :global(.intro) {
      font-size: 0.8em;
    }

    :global(.signal-disc-category) {
      margin-right: 0.2em;
    }
  }

  .icon {
    @include svg-bold();
  }
}
