// Sizings
$nav-height: 86px;
$content-min-height: calc(100vh - $nav-height);
$main-content-width: 1040px;
$new-profile-page-width: 1473px;
$min-new-profile-page-width: 1200px;
$selector-mgmt-min-width: 1600px;

// Colors
$default-heading-color: #2ca7e4;

// DISC Colors
$disc-D: #db3c48;
$disc-Di: #e5561f;
$disc-DI: #f48119;
$disc-Id: #ffb727;
$disc-I: #f7cf0d;
$disc-Is: #d3e000;
$disc-IS: #afd80a;
$disc-Si: #88c100;
$disc-S: #11b21b;
$disc-Sc: #51b48e;
$disc-SC: #00b6bc;
$disc-Cs: #007fb6;
$disc-C: #2f5fa5;
$disc-Cd: #6756b2;
$disc-CD: #93359b;
$disc-Dc: #c62e85;

$disc-D-light: #ffeeee;
$disc-Di-light: #fff1e9;
$disc-DI-light: #fef6f4;
$disc-Id-light: #fffceb;
$disc-I-light: #fff8e2;
$disc-Is-light: #fffdf1;
$disc-IS-light: #fffee6;
$disc-Si-light: #fffce0;
$disc-S-light: #f7fdf7;
$disc-Sc-light: #f2fdf8;
$disc-SC-light: #f0fdfe;
$disc-Cs-light: #e4f5fc;
$disc-C-light: #d3e5f7;
$disc-Cd-light: #d8d7eb;
$disc-CD-light: #e9ddee;
$disc-Dc-light: #f6f7fa;

$disc-D-very-light: #fbeced;
$disc-Di-very-light: #fceee9;
$disc-DI-very-light: #fef2e8;
$disc-Id-very-light: #fff8e9;
$disc-I-very-light: #fcf8e6;
$disc-Is-very-light: #fbfbe6;
$disc-IS-very-light: #f7fbe7;
$disc-Si-very-light: #f3f9e6;
$disc-S-very-light: #e7f7e8;
$disc-Sc-very-light: #eef7f4;
$disc-SC-very-light: #e6f8f8;
$disc-Cs-very-light: #e6f2f8;
$disc-C-very-light: #eaeff6;
$disc-Cd-very-light: #f0eef7;
$disc-CD-very-light: #f4ebf5;
$disc-Dc-very-light: #f9eaf3;

// Style Guide Colors
$blue: #2ca7e4;
$mid-blue: #a2e2fc;
$baby-blue: #def0f9;
$light-crystal-blue: #e7f7fe;
$crystal-midnight-blue: #01293d;
$action-blue: #1e749f;
$background-blue: #345464;
$crystal-light-gray: #fbfbfb;
$white: #ffffff;
$black: #000000;
$red: $disc-D;
$orange: $disc-Id;
$yellow: $disc-Id;
$green: $disc-S;
$coral: #ff7f56;
$accent-orange: #f09025;
$accent-orange-light: #f8c792;
$yellow: $disc-Id;
$teal: $disc-Sc;
$dark-blue: #00293d;
$gray-text: #6c6c6c;
$light-gray-text: #80949e;
$light-gray-text-two: #95a3aa;
$gray-background: #e3e3e3;
$gray-background-two: #fafafa;
$blue-transparent: rgba(85, 174, 246, 0.05);
$blue-transparent-saturated: rgba(162, 226, 252, 0.25);
$accent-yellow: #ffcb1b;
$accent-yellow-light: #ffeeb6;

// Myers Briggs Colors
$ENFP: $disc-D;
$ESFJ: $disc-Di;
$ESFP: $disc-DI;
$INFP: $disc-Id;
$INFJ: $disc-I;
$ISFP: $disc-Is;
$ISFJ: $disc-IS;
$INTP: $disc-Si;
$INTJ: $disc-S;
$ISTP: $disc-Sc;
$ISTJ: $disc-SC;
$ENTP: $disc-Cs;
$ENTJ: $disc-C;
$ESTP: $disc-Cd;
$ESTJ: $disc-CD;
$ENFJ: $disc-Dc;

// Enneagram Colors
$enneagram-1: $disc-D;
$enneagram-2: $disc-DI;
$enneagram-3: $disc-I;
$enneagram-4: $disc-IS;
$enneagram-5: $disc-S;
$enneagram-6: $disc-SC;
$enneagram-7: $disc-C;
$enneagram-8: $disc-CD;
$enneagram-9: $disc-Dc;

// Renamed: Material Colors
$gray-1: #212121;
$gray-2: rgba(0, 0, 0, 0.68);
$gray-3: rgba(0, 0, 0, 0.54);
$gray-4: rgba(0, 0, 0, 0.38);
$gray-5: rgba(0, 0, 0, 0.34);
$gray-6: rgba(0, 0, 0, 0.24);
$gray-7: rgba(0, 0, 0, 0.12);
$gray-8: rgba(0, 0, 0, 0.08);
$gray-9: rgba(0, 0, 0, 0.04);

// Material Colors
$material-87: rgba(0, 0, 0, 0.87);
$material-68: rgba(0, 0, 0, 0.68);
$material-54: rgba(0, 0, 0, 0.54);
$material-38: rgba(0, 0, 0, 0.38);
$material-34: rgba(0, 0, 0, 0.34);
$material-24: rgba(0, 0, 0, 0.24);
$material-12: rgba(0, 0, 0, 0.12);
$material-08: rgba(0, 0, 0, 0.08);
$material-04: rgba(0, 0, 0, 0.04);

// Greyscale
$crystal-background-gray: #f5f5f5;
$crystal-highlight-gray: #bbbbbb;
$crystal-line-gray: #e5e5e5;
$crystal-line-light-gray: #e0e0e0;
$disc-empty: #bdbdbd;
$gray-outline: #c4c4c4;
$disabled-gray: #a8a8a8;

// Sizes
$small-mobile: 500px;
$mobile: 750px;
$tablet: 900px;

$side-nav-width: 300px;
$side-nav-mini-width: 75px;
$mobile-more-size: 2rem;
$mobile-width: 940px;
$tablet-width: 1040px;

// States
$color-success: #11b21b;
$color-error: #db3c48;
$color-error-background: #fbeced;
$success: #11b21b;
$error: #db3c48;
$warning: #f09025;
$info: #2ca7e4;
$success-light: #e7f7e8;
$error-light: #fbeced;
$placeholder-color: #757575;

$gray-info: #777777;

// breakpoints
$mobile-width: 940px;
$tablet-width: 1040px;
$profile-table-max-width: 1450px;

$midnight-blue: #01293d;
$crystal-blue: #2ca7e4;
$crystal-light-blue: #a2e2fc;
$accent-orange: #f09025;
$accent-yellow: #ffcb1b;
$default-text: #01293d;
$accessible-gray: #6c6c6c;
$modern-gray: #80949e;
$border-gray: #e0e0e0;
$border-dark: #6c6c6c;
$action-blue: #1e749f;
$bg-gray-1: #e3e3e3;
$bg-gray-2: #f7f7f7;
$bg-gray-3: #d9d9d9;
$light-blue-gradient-1: #aac7db08;
$light-blue-gradient-2: #a2e2fc12;
$midnight-blue-gradient-1: #01293d;
$midnight-blue-gradient-2: #01293d80;
$default-heading-color: #2ca7e4;
