@import '@crystal-eyes/constants.scss';

$content-bg-color: #f5f5f5;

.mbti {
  display: flex;
  flex-direction: column;
  background-color: $content-bg-color;
  
  :global(.heading) {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    
    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.2));
  }

  :global(.progress-bar) {
    height: 10px;
    background-color: #dedede;

    :global(.bar) {
      height: 100%;
      background: linear-gradient(90deg, #2ca7e4, #11b21b);
      transition: all .2s;
    }
  }

  :global(.question-nav) {
    display: grid;
    grid-template-columns: auto 1fr auto;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    padding: 20px;

    a:global(.deactivated) {
      color: #7e7e7e;
      pointer-events: none;
    }

    p {
      text-align: center;
      color: #7e7e7e;
      margin-top: 20px;
    }

    @media (max-width: $tablet) {
      span {
        display: none;
      }
    }
  }

  :global(.centerQuestion) {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3em;
    line-height: 2em;
    color: #7e7e7e;
    background-color: white;
    border: 1px solid #dedede;
    padding: 15px;
    border-radius: 3px;
  }

  :global(.response-area) {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    padding: 25px;
    box-sizing: border-box;

    @media (max-width: $tablet) {
      display: block;
    }

    :global(.question) {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.1em;
      line-height: 1.5em;
      text-align: center;
      color: #7e7e7e;
      background-color: white;
      border: 1px solid #dedede;
      padding: 15px;
      border-radius: 3px;
    }

    :global(.rating) {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 20px;
      margin: 40px;
      position: relative;

      @media (max-width: $tablet) {
        margin: 0;
        flex-direction: column;
      }

      :global(.assessmentOption) {
        @media (max-width: $tablet) {
          background-color: white;
          width: 100%;
          text-align: center;
          align-items: center;
          display: flex;
          justify-content: center;
          height: 70px;
          border: 1px solid #dedede;
        }
      }

      :global(.line) {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        height: 1px;
        z-index: 500;

        background-color: #cecece;

        @media (max-width: $tablet) {
          display: none;
        }
      }

      span {
        color: #7e7e7e;
      }

      a {
        color: #7e7e7e;
        transition: all .2s;
        user-select: none;

        :global(.rating-number) {
          border: 1px solid #7e7e7e;
          border-radius: 50%;
          z-index: 1500;
          position: relative;
          padding: 10px 15px;
        }
        
        &:global(.selected) {
          font-weight: bold;
          text-decoration: none;
          color: white;

          :global(.rating-number) {
            border: 1px solid #7e7e7e;
            background-color: $crystal-blue;
          }
        }

        @media (min-width: $tablet){
          :not(.removeScale):hover {
            transform: scale(1.1);
          }
          
          &:hover {
            font-weight: bold;
            text-decoration: none;
            color: white;
  
            :global(.rating-number) {
              border: 1px solid #7e7e7e;
              background-color: $crystal-blue;
            }
          }
        }
      }
    }
  }

  :global(.submit-button) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
  }
}