@import '@crystal-eyes/constants.scss';

.container {
  display: flex;
  margin-left: 8px;
  align-items: center;
  line-height: 0;
  text-transform: none;

  &.noMargin {
    margin-left: 0;
  }

  &.animate .badge {
    animation-name: dropIn;
    animation-duration: 0.8s;
    animation-timing-function: cubic-bezier(0.6, 0.5, 0.38, 1.45);
    animation-iteration-count: 1;
  }
}

@keyframes dropIn {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }
  60% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.badge {
  width: 16px;
  height: 16px;
  max-height: 16px;
  max-width: 16px;
  &:hover {
    & + .tooltip {
      display: block;
    }
  }
}

.tooltip {
  padding: 6px 14px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 3px 9px 1px rgba(0, 0, 0, 0.1);
  color: $material-87;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-decoration: none;
  width: 260px;
  font-family: var(--font-lato);
  font-weight: 400;
}
