@import '@crystal-eyes/constants.scss';

.profileWrapper {
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  &:hover {
    background: $crystal-line-light-gray;
  }
}

.namePocket {
  display: flex;
  align-items: center;
  gap: 4px;
}
