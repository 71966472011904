@import '@crystal-eyes/constants.scss';

.createModal {
  background: $white;
  overflow: auto;
}

.groupNameContainer {
  padding: 40px 24px;

  &.required {
    .name,
    .editHelper {
      color: $red;
    }

    .asterisk {
      opacity: 1;
    }
  }
}

.nameRow {
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}

.formColumns {
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding: 0 24px;

  @media (max-width: 720px) {
    flex-direction: column;
  }
}

.name {
  transition: all 0.3s;
  font-size: 24px;
  color: $crystal-midnight-blue;
}

.editHelper {
  transition: all 0.3s;
  font-size: 14px;
  margin-left: 8px;
  line-height: 20px;
}

.asterisk {
  transition: all 0.3s;
  opacity: 0;
  color: $red;
  margin-left: 4px;
}

.nameInput {
  font-size: 24px;
  outline: none;
  border: none;
  flex-grow: 1;
}

.column {
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (max-width: 720px) {
    padding-bottom: 16px;
  }
}

.iconHeader {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.iconHeaderIcon {
  display: block;
  margin-right: 10px;
}

.iconHeaderTitle {
  font-size: 18px;
  color: $crystal-midnight-blue;
}

.settingsSection {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.inputContainer {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.label {
  transition: all 0.3s;
  font-size: 16px;
  color: $material-68;
  display: flex;

  &.required {
    color: $red;

    .requiredText {
      opacity: 1;
    }
  }
}

.requiredText {
  transition: all 0.3s;
  font-size: 16px;
  margin-left: 4px;
  opacity: 0;

  color: $red;
}

.selectedEntities {
  display: flex;
  flex-wrap: wrap;
}

.badgeContainer {
  margin-bottom: 8px;
  margin-right: 8px;
}

.info {
  font-size: 14px;
  font-style: italic;
  color: $material-34;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: 0px -2px 2px $material-12;
  margin-top: 16px;
  padding: 8px 0;
}
