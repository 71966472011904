@import '@crystal-eyes/constants.scss';

.preMeeting {
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 86px);
  font-family: var(--font-lato);
  height: 100%;
  background: $white;
}
