@import '@crystal-eyes/constants.scss';

.container {
  max-width: 100%;
  width: 365px;
  position: relative;
  &.wide {
    width: 500px;
    max-width: 100%;
  }

  &.fullWidth {
    width: 100%;
  }
}

.input {
  width: 100%;
  font-size: 14px;
  font-family: var(--font-lato);
  font-weight: 400;

  &.short {
    height: 38px;
    border: none;
    padding-left: 36px;
    outline: none;
    background: transparent;
  }
}

.icon-wrapper {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 12px;
  opacity: 0.54;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-icon {
  height: 16px;
  width: 16px;
}
