@import '@crystal-eyes/constants.scss';
@import '@crystal-eyes/helpers.scss';

.container {
  display: flex;
  align-items: center;
  height: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.grade-container {
  @extend .background-disc-color-medium;
}

.fill {
  display: block;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: slideIn 0.6s 1 ease;
  transform-origin: left;

  &.empty {
    background-image: linear-gradient(45deg, $blue 25%, darken($blue, 12));
  }

  @media print {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact !important;
  }
}

.grade-fill {
  @extend .background-disc-color;
}

@keyframes slideIn {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}
