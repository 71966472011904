@import '@crystal-eyes/constants.scss';

.container {
  animation: fade-in 0.5s;
  &.sequential {
    animation: drop-in 1.3s ease;
    &:nth-child(1) {
      animation-duration: 0.3s;
    }
    &:nth-child(2) {
      animation-duration: 0.5s;
    }
    &:nth-child(3) {
      animation-duration: 0.7s;
    }
    &:nth-child(4) {
      animation-duration: 0.9s;
    }
    &:nth-child(5) {
      animation-duration: 1.1s;
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes drop-in {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
