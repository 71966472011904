@import '@crystal-eyes/constants.scss';

.tally {
  color: $blue;
  font-size: 26px;
  font-weight: 700;
}

.limit {
  font-weight: 300;
  display: flex;
  align-items: center;
}
